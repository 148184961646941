import { Checkbox, FormControlLabel, FormGroup, SxProps, Theme } from '@mui/material';
import React, { CSSProperties, ChangeEvent, useEffect, useState } from 'react';

import { tokens } from 'context/theme.context';

type CustomCheckboxProps = {
  label: string;
  handleChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
  value?: boolean;
  fieldName: string;
  span?: number;
  handleChangeCallback?: (value: boolean) => void;
  margin?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  readOnly?: boolean;
  optional?: boolean;
  autoSave?: () => void;
  style?: CSSProperties;
  sx?: SxProps<Theme>;
};

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  label,
  handleChange,
  disabled,
  value,
  fieldName,
  span,
  handleChangeCallback,
  margin,
  setFieldValue,
  readOnly,
  optional,
  autoSave,
  style,
  sx,
}) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!readOnly) {
      handleChange && handleChange(event);
      handleChangeCallback && handleChangeCallback(checked);
      setFieldValue && setFieldValue(fieldName, checked);
      autoSave && autoSave();
      setChecked(checked);
    }
  };

  useEffect(() => {
    setChecked(!!value);
  }, [value]);

  return (
    <FormGroup
      style={{
        gridColumn: span ? 'span ' + span : undefined,
        ...style,
      }}
    >
      <FormControlLabel
        onClick={(e) => e.stopPropagation()}
        sx={{ '& .MuiCheckbox-root': { padding: '0px 9px', color: tokens().secondary } }}
        control={<Checkbox disabled={disabled} checked={checked} onChange={handleCheckboxChange} name={fieldName} />}
        label={label + (optional ? ' (optional)' : '')}
      />
    </FormGroup>
  );
};

export default CustomCheckbox;
