import * as React from 'react';

import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { tokens } from 'context/theme.context';

type CustomRadioGroupProps = {
  options: any[];
  label: string;
  renderLabel?: () => React.ReactNode;
  value?: any[];
  fieldName: string;
  span?: number;
  optionsColumn?: number;
  error?: any;
  handleChange?: (value: any) => void;
  handleChangeCallback?: (value: any) => void;
  disabled?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  touched?: boolean;
  row?: boolean;
  style?: React.CSSProperties;
  readOnly?: boolean;
  optional?: boolean;
};

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
  options,
  label,
  renderLabel,
  value = [],
  fieldName,
  handleChange,
  handleChangeCallback,
  disabled,
  setFieldValue,
  touched,
  span,
  optionsColumn = 2,
  row,
  style,
  readOnly,
  optional,
  error,
}) => {
  const colors = tokens();

  const [selectedOption, setSelectedOption] = useState<any>(value);

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!readOnly) {
      const newValue = event.target.value;
      setSelectedOption(newValue);
      handleChange && handleChange(newValue);
      setFieldValue && setFieldValue(fieldName, newValue);
      handleChangeCallback && handleChangeCallback(newValue);
    }
  };

  useEffect(() => {
    if (value.length) setSelectedOption(value);
  }, [value]);

  return (
    <Box
      sx={{
        gridColumn: 'span ' + span,
        '& .Mui-checked': {
          color: colors.secondary + ' !important',
        },
      }}
    >
      {renderLabel ? (
        renderLabel()
      ) : (
        <Typography sx={{ whiteSpace: 'pre-line' }}> {label + (optional ? ' (optional)' : '')}</Typography>
      )}
      <RadioGroup
        row={row ? row : false}
        value={selectedOption}
        onChange={handleSelectChange}
        sx={{
          gridColumn: 'span ' + span,
          justifyContent: 'center',
          alignContent: 'center',
          display: 'grid',
          paddingLeft: '10px',
          gridTemplateColumns: `repeat(${optionsColumn}, 1fr)`,
          '& .MuiRadio-root': { padding: '5px' },
          ...style,
        }}
      >
        {options?.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.key}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {error && touched && (
        <Typography fontSize="0.75em" color="#d32f2f">
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default CustomRadioGroup;
