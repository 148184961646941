export interface UserModel {
  id?: number;
  username: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  title: string;
  authorities?: string[];
  user_group: UserGroupModel;
  shipping_carriers: ShippingCarrierModel[];
}

export interface ShippingCarrierModel {
  id: number;
  carrier_name: string;
}

export interface UserGroupModel {
  id?: number;
  description: string;
  group_name: string;
  level: string;
  validator?: boolean;
}

export interface OrderFilterModel {
  id?: number;
  filter_name: string;
  filter: any;
}

export enum STATION {
  UNBATCHED = 'unbatched',
  SORTING = 'sorting',
  SCANNING = 'scanning',
  SHIPPING = 'shipping',
  COMPLETED = 'completed',
}

export enum ORDERS_TAB {
  PENDING = 'pending',
  BATCHED = 'batched',
  RESOLVED = 'resolved',
  STOP_CHECK = 'stop-check',
  PARTIAL_FULFILLMENT = 'partial-fulfillment',
  DELETED = 'deleted',
  FAILED_SHIPPING = 'failed-shipping',
}

export interface OrderModel {
  id?: number;
  shopify_id: string;
  order_name: string;
  order_number: number;
  order_date: string;
  ship_by: string;
  batch_id?: number;
  customer_id?: number | null;
  currency: string;
  subtotal_price: number;
  total_discounts: number;
  total_line_items_price: number;
  total_outstanding: number;
  total_price: number;
  total_tax: number;
  taxes_included: boolean;
  tags?: string;
  total_weight?: number;
  shipping_country?: string;
  shipping_country_code?: string;
  fraudulent_status?: string;
  note?: string;
  shopify_data?: OrderShopifyData;
  dear_id?: string;
  dear_data?: any;
  dear_errors?: any[];
  dear_order_number?: string;
  shipping_status?: string;
  tracking_number?: string;
  tracking_url?: string;
  shipping_date?: string;
  shipping_carrier_id?: number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  line_items: LineItemModel[];
  batch: BatchModel;
  customer: CustomerModel;
  tote_bin_number: number;
  current_station: STATION;
  date_batched?: string;
  date_sorted?: string;
  date_scanned?: string;
  shipping_carrier: ShippingCarrierModel;
  partial_fulfillment_flag?: boolean;
  sorting_done?: boolean;
  stop_check_flag?: boolean;
  stop_check_remarks?: string;
  dear_pick_status?: string;
  dear_pack_status?: string;
  wudi_status?: string;
}

export interface BatchModel {
  id: number;
  batch_name: string;
  date_sorted: string;
  sort_status: string;
  status: string;
  orders: OrderModel[];
  updated_at: string;
  picklist_printed: boolean;

  //
  unselected?: boolean;
}

export interface LineItemModel {
  id: number;
  shopify_id: string;
  order_id: number;
  sku: string;
  quantity: number;
  picked_count: number;
  tote_bin_number: number;
  price: string;
  title: string;
  variant_title: string;
  product_id: string;
  variant_id: string;
  shopify_data: any;
  scanned: number; // for scanning station
  missing_count: number;
  product_type: string;
  stock_locator: string;
  dear_errors?: any;
  oos_qty?: number;
}

export interface CustomerModel {
  id?: number;
  shopify_id: string;
  email: string;
  verified_email: boolean;
  first_name: string;
  last_name: string;
  currency: string;
  tags: string;
  shopify_data: any;
}

export interface OrderShopifyData {
  shipping_address: any;
  billing_address: any;
}

export interface StockLocationModel {
  sku: string;
  name: string;
  uom: string;
  stock_locator: string;
}
