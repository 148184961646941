import * as yup from 'yup';

import { ApiQuery, FieldInput } from 'model/interface';
import { BatchModel, OrderModel } from 'model/Entities';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { ConfirmationDialog, CustomForm, CustomModal, CustomTable, Header, RegularButton } from 'components';
import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { capitalizeWord, checkIfOutOfStock, formatDate, formatDateTime, getSkuNumber, tranformFormErrors } from 'utils';
import { fixToteBinNumber, getBatches, pickListPrinted, updateBatch } from 'api/batch';
import {
  getOrders,
  getSkuLocations,
  partialFulfillmentMultiple,
  printLabel,
  processShipment,
  removeFromBatch,
} from 'api/order';

import { BreadcrumbContext } from 'context/breadcrumb.context';
import CombinePickList from './CombinePickList';
import CommercialInvoice from './CommercialInvoice';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import OrderValidation from 'screens/Orders/OrderValidation';
import PickList from './PickList';
import { SHIPPING_COMPANY } from 'utils/constant';
import { UserContext } from 'context/user.context';
import moment from 'moment';
import { process_shipment_schema } from 'model/schema';
import { tokens } from 'context/theme.context';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';

const Batches = () => {
  const colors = tokens();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState<BatchModel>();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [openViewOrders, setOpenViewOrders] = useState(false);
  const [openResolveOrders, setOpenResolveOrders] = useState(false);
  const [openSummarizedErrors, setOpenSummarizedErrors] = useState(false);
  const [summarizedErrors, setSummarizedErrors] = useState<any[]>([]);

  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [selectedOrdersDetails, setSelectedOrdersDetails] = useState<OrderModel[]>([]);
  const [orderCount, setOrderCount] = useState<number>(0);

  const [selectedOrder, setSelectedOrder] = useState<OrderModel>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openProcessShipment, setOpenProcessShipment] = useState(false);
  const [processShipmentLoading, setProcessShipmentLoading] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState<string>();
  const [skuLocations, setSkuLocations] = useState<any>({});
  const [skuLocationsLoading, setSkuLocationsLoading] = useState(false);
  const [documentToPrint, setDocumentToPrint] = useState<string>();
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [openConfirmPartial, setOpenConfirmPartial] = useState(false);
  const [loadingPartial, setLoadingPartial] = useState(false);
  const [fixToteBinLoading, setFixToteBinLoading] = useState(false);

  const [openPrintCPConfirmation, setOpenPrintCPConfirmation] = useState(false);
  const [loadingPrintCP, setLoadingPrintCP] = useState(false);

  const batchTableRef: any = useRef();
  const orderTableRef: any = useRef();
  const printableRef = useRef(null);
  const printableCombineRef = useRef(null);
  const printableCommercialInvoice = useRef(null);

  const hasToteBinIssue = useMemo(
    () =>
      !!selectedOrdersDetails.length &&
      !selectedOrdersDetails.every((order, index) => order.tote_bin_number === index + 1),
    [selectedOrdersDetails]
  );

  const handlePrintPickList = useReactToPrint({
    content: () => printableRef.current,
  });

  const handlePrintCombinePickList = useReactToPrint({
    content: () => printableCombineRef.current,
  });

  const handlePrintCommercialInvoice = useReactToPrint({
    content: () => printableCommercialInvoice.current,
  });

  const handlePartialFulfillment = async () => {
    if (selectedOrders.length) {
      try {
        setLoadingPartial(true);
        await partialFulfillmentMultiple(selectedOrders);
        setOpenConfirmPartial(false);
        orderTableRef.current.refreshData();
      } catch (error) {
      } finally {
        setLoadingPartial(false);
      }
    }
  };

  const processOrderShipment = (company: string, pickup_date: Date, data: any) => {
    setProcessShipmentLoading(true);
    const date = moment(pickup_date).format('YYYY-MM-DD');
    setOpenProcessShipment(false);
    const include_notes = Object.keys(data).filter((key) => key !== 'pickup_date' && data[key] === 'yes');
    processShipment(company, selectedOrders, date, include_notes)
      .then((res) => {
        if (res.data && !res.data.error && !res.data.code) {
          downloadPdf(res.data, company);
          enqueueSnackbar(`Order successfully processed!`, { variant: 'success' });
        } else {
          enqueueSnackbar(`Error in processing shipment!`, { variant: 'error' });
        }
        orderTableRef.current.refreshData();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(`Order was not successfully processed!`, { variant: 'error' });
      })

      .finally(() => {
        setSelectedCarrier(undefined);
        setProcessShipmentLoading(false);
      });
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Batches' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedBatch?.id) {
      setSkuLocationsLoading(true);
      getSkuLocations(selectedBatch.id)
        .then((res) => setSkuLocations(res.data))
        .finally(() => setSkuLocationsLoading(false));
    }
  }, [selectedBatch?.id]);

  useEffect(() => {
    if (selectedBatch && documentToPrint) {
      document.title = `Batch-SN-${selectedBatch.id}-${documentToPrint}`;
    } else {
      document.title = 'WUDI';
    }
  }, [selectedBatch, documentToPrint]);

  const invalidOrderSelected = useMemo(() => {
    if (selectedCarrier && selectedCarrier === SHIPPING_COMPANY.NINJAVAN) {
      return selectedOrdersDetails.filter(
        (order) =>
          selectedOrders.includes(order.order_name) &&
          !['SG', 'MY', 'TH', 'ID', 'VN', 'PH', 'MM'].includes(order.shipping_country_code as string)
      );
    }
    return [];
  }, [selectedCarrier, selectedOrdersDetails, selectedOrders]);

  const getSortingStatusLabel = (value: string) => {
    switch (value) {
      case 'open':
      case 'error':
        return 'Not Started';

      case 'complete':
        return 'Completed';

      default:
        return capitalizeWord(value);
    }
  };

  const getShippingStatusLabel = (orders: OrderModel[], created_at: string) => {
    const totalOrders = orders.length;
    const shippedCount = orders.filter((order) => order.shipping_status === 'shipped').length;
    const now = new Date();
    const diffInMs = now.getTime() - new Date(created_at).getTime();
    const hoursInMs = 72 * 60 * 60 * 1000;
    const past72Hours = diffInMs > hoursInMs;

    let label = 'Pending';
    let color: any = 'warning';
    if (shippedCount === totalOrders) {
      label = 'Completed';
      color = 'success';
    } else {
      if (shippedCount > 0) {
        label = 'In progress';
        color = 'info';
      }
      if (past72Hours) {
        label = label + ' (alert)';
        color = 'secondary';
      }
    }

    return <Chip variant="outlined" size="small" color={color} label={label} sx={{ fontWeight: 'bold' }} />;
  };

  const getBatchColor = useCallback((status: string) => {
    switch (status) {
      case 'error':
        return colors.redAccent;

      case 'partial':
        return colors.orangeAccent;

      default:
        return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const printCombinedPickList = async () => {
    try {
      if (selectedBatch && !selectedBatch.picklist_printed) {
        setLoadingPrintCP(true);
        await pickListPrinted(selectedBatch.id);
        setSelectedBatch({ ...selectedBatch, picklist_printed: true });
      }
      setDocumentToPrint('Combine-Pick-List');
      handlePrintCombinePickList();
    } finally {
      setLoadingPrintCP(false);
    }
  };

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Batch Number',
      flex: 1,
      renderCell: ({ value, row }) => (
        <Box display="flex" gap="5px">
          <Typography>{value}</Typography>
          {row.picklist_printed && <Chip color="primary" label="Printed" size="small" />}
        </Box>
      ),
    },
    {
      field: 'batch_name',
      headerName: 'Batch Name',
      flex: 2,
      renderCell: ({ value, row }) => (
        <Typography fontWeight="bold" color={getBatchColor(row.status)}>
          {value}
        </Typography>
      ),
    },
    {
      field: 'orders',
      headerName: 'Order Count',
      flex: 0.8,
      renderCell: ({ value }) => <Typography>{value.length}</Typography>,
    },
    {
      field: 'shipping_status',
      headerName: 'Batch Status',
      flex: 1.2,
      renderCell: ({ row }) => (row.status === 'error' ? <></> : getShippingStatusLabel(row.orders, row.created_at)),
    },
    {
      field: 'sort_status',
      headerName: 'Sorting Status',
      flex: 1,
      renderCell: ({ value, row }) =>
        row.status === 'error' ? (
          <></>
        ) : (
          <Chip
            variant="outlined"
            size="small"
            color={value === 'complete' ? 'success' : 'info'}
            label={getSortingStatusLabel(value)}
            sx={{ fontWeight: 'bold' }}
          />
        ),
    },
    {
      field: 'created_at',
      headerName: 'Dates',
      flex: 1.5,
      renderCell: ({ value, row }) => (
        <Box sx={{ '& p': { fontSize: '13px' } }}>
          <Typography>Created: {formatDateTime(value)}</Typography>
          <Typography>Updated: {formatDateTime(row.updated_at)}</Typography>
        </Box>
      ),
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 1.5,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <Box flex={1} alignItems="center" display="flex" gap="10px">
          <RegularButton
            color="info"
            onClick={(e) => {
              setSelectedBatch(params.row);
              setOpenUpdateModal(true);
            }}
            label="Rename"
            variant="outlined"
            size="small"
          />
          <RegularButton
            color="success"
            onClick={() => {
              setSelectedBatch(params.row);
              setOpenViewOrders(true);
            }}
            label="View Orders"
            variant="outlined"
            size="small"
          />
        </Box>
      ),
    },
  ];
  const order_table_columns: GridColDef[] = [
    {
      field: 'order_name',
      headerName: 'Order No.',
      flex: 1,
      renderCell: ({ value, row }) => (
        <Box display="flex" gap="10px">
          <Typography>{value}</Typography>
          {row.shipping_carrier_data?.error && (
            <Tooltip title={<Typography>{row.shipping_carrier_data?.error}</Typography>} arrow>
              <ErrorOutlineOutlinedIcon color="error" />
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      field: 'order_date',
      headerName: 'Order Date',
      flex: 1,
      renderCell: ({ value }) => <Typography>{formatDate(value)}</Typography>,
    },
    {
      field: 'line_items',
      headerName: 'SKU',
      flex: 1.5,
      sortable: false,
      renderCell: ({ row }) => (
        <Box
          display="grid"
          gridTemplateColumns={selectedBatch?.status === 'partial' ? '1fr' : '1fr 1fr'}
          columnGap="10px"
        >
          {row.line_items.map((item: any, index: number) => {
            const oos = checkIfOutOfStock(row.dear_errors ?? [], item.sku);
            return (
              <Typography key={index} color={oos ? 'error' : undefined}>
                {item.sku}
                {oos ? ' (OOS)' : ''}
              </Typography>
            );
          })}
        </Box>
      ),
    },
    {
      field: 'dear_errors',
      headerName: 'Errors',
      flex: 2,
      renderCell: ({ value }) => (
        <Box>
          {value?.map((error: any, index: number) => (
            <Typography key={index} color={colors.redAccent} fontSize="12px" sx={{ textWrap: 'wrap' }}>
              • {error.Exception}
            </Typography>
          ))}
        </Box>
      ),
    },
    {
      field: 'shipping_country',
      headerName: 'Shipping Country',
      flex: 1,
      sortable: false,
    },
    {
      field: 'fraudulent_status',
      headerName: 'Fraudulent Status',
      flex: 1,
      sortable: false,
    },
    {
      field: 'current_station',
      headerName: 'Current Station',
      flex: 1,
      renderCell: (params) => <Typography>{capitalizeWord(params.value)}</Typography>,
    },
    {
      field: 'tote_bin_number',
      headerName: 'Tote Bin #',
      flex: 0.8,
    },
    {
      field: 'tracking_number',
      headerName: 'Tracking Number',
      flex: 1.5,
      renderCell: ({ value, row }) =>
        value ? (
          <Box>
            {row.shipping_carrier && (
              <Typography>
                {row.shipping_carrier.carrier_name} - {value}
              </Typography>
            )}

            {row.shipping_status && (
              <Chip
                size="small"
                color={
                  row.shipping_status === 'ready'
                    ? 'warning'
                    : row.shipping_status === 'confirmed'
                    ? 'primary'
                    : row.shipping_status === 'shipped'
                    ? 'success'
                    : 'info'
                }
                label={row.shipping_status === 'ready' ? 'Ready to Ship' : capitalizeWord(row.shipping_status)}
                sx={{ paddingInline: '5px', fontWeight: 'bold' }}
              />
            )}
          </Box>
        ) : (
          <></>
        ),
    },
    {
      field: 'tags',
      headerName: 'Tags',
      flex: 1,
      sortable: false,
    },
    {
      field: 'note',
      headerName: 'Notes',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1.5,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <Box flex={1} justifyContent="center" display="flex" gap="10px" width="100%">
          {selectedBatch?.status === 'error' ? (
            <Box display="flex" gap="10px">
              <RegularButton
                color="success"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenResolveOrders(true);
                  setSelectedOrders([params.row.order_name]);
                }}
                label="Resolve"
                variant="outlined"
                size="small"
              />
            </Box>
          ) : (
            <>
              {/* <RegularButton
                color="success"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedCarrier(undefined);
                  setSelectedOrder(params.row);
                  setLoadingPrint(true);
                  printLabel([params.row.order_name])
                    .then((res) => {
                      if (res.data)
                        downloadPdf(res.data, params.row.shipping_carrier?.carrier_name, params.row.order_name);
                    })
                    .finally(() => setLoadingPrint(false));
                }}
                label="Print Label"
                variant="outlined"
                size="small"
                disabled={!params.row.tracking_number}
                loading={loadingPrint}
              /> */}
              <RegularButton
                color="error"
                onClick={(e) => {
                  setSelectedOrder(params.row);
                  setOpenDeleteConfirmation(true);
                  e.stopPropagation();
                }}
                label="Remove"
                variant="outlined"
                size="small"
              />
            </>
          )}
        </Box>
      ),
    },
  ];

  const viewOrders = async (query: ApiQuery) => {
    if (selectedBatch) {
      setSelectedOrdersDetails([]);
      const { data } = await getOrders({ ...query, batch_id: selectedBatch.id + '' });
      setSelectedOrdersDetails(data.rows);
      setOrderCount(data.count);
    }
  };

  const getAllBatches = async (query: ApiQuery) => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getBatches(query);
        setRows(res.data.rows);
        setRowCount(res.data.count);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching activity logs:', error);
      }
    };

    fetchData();
  };

  const downloadPdf = (blob: any, carrier: string, orderName?: string) => {
    const pdfBlob = new Blob([blob], { type: 'application/pdf' });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(pdfBlob);
    link.href = url;

    const company = carrier === SHIPPING_COMPANY.ARAMEX ? 'AR' : carrier === SHIPPING_COMPANY.JT ? 'JT' : 'NV';
    let label =
      orderName ?? `Combined (${selectedOrders.length ? selectedOrders.length : orderCount} of ${orderCount})`;
    if (selectedOrders.length === 1) {
      label = '-' + selectedOrders[0];
    }
    link.download = `Batch-SN-${selectedBatch?.id}-Shipping-Labels-${company}-${label}.pdf`;

    link.click();
    window.URL.revokeObjectURL(url);
  };

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true); //when button submit is clicked turns on the loading animation

    try {
      await updateBatch(data.id, data);
      enqueueSnackbar(`User successfully updated!`, { variant: 'success' });

      setOpenUpdateModal(false);
      batchTableRef.current.refreshData();
    } catch (error: any) {
      if (error.response?.data) {
        formikHelpers.setErrors(tranformFormErrors(error.response.data));
      }
    } finally {
      setButtonLoading(false);
    }
  };

  const countOccurrences = (array: string[]) => {
    let counts: any = {};

    array.forEach(function (item) {
      if (counts[item]) {
        counts[item]++;
      } else {
        counts[item] = 1;
      }
    });

    return counts;
  };

  const openErrors = () => {
    setOpenSummarizedErrors(true);
    const errors = selectedOrdersDetails
      .flatMap((order) => order.dear_errors?.map((error) => error.Exception))
      .filter((error) => !!error);
    const lineItemsWithError = selectedOrdersDetails
      .flatMap((order) => order.line_items)
      .filter((item) => !!item.dear_errors && checkIfOutOfStock(item.dear_errors, item.sku))
      .reduce((result: any, item) => {
        result[item.sku] = (result[item.sku] || 0) + item.quantity;
        return result;
      }, {});

    const uniqueErrors = countOccurrences(errors);
    setSummarizedErrors(
      Object.keys(uniqueErrors).map((key) => ({
        error: key,
        count: uniqueErrors[key],
        total_qty: lineItemsWithError[getSkuNumber(key)] ?? 0,
      }))
    );
  };

  const handleSubmitProcessShipment = async (data: any, formikHelpers: FormikHelpers<any>) => {
    if (selectedCarrier) processOrderShipment(selectedCarrier, data.pickup_date, data);
  };

  const disableCommercialInvoiceOrPrintLabel: boolean = useMemo(() => {
    const orders = selectedOrders.length
      ? selectedOrdersDetails.filter((item) => selectedOrders.includes(item.order_name))
      : selectedOrdersDetails;
    return orders.some((order) => !order.tracking_number);
  }, [selectedOrders, selectedOrdersDetails]);

  const processShipmentFields: FieldInput[] = useMemo(() => {
    const selected = selectedOrdersDetails.filter((order) => selectedOrders.includes(order.order_name) && !!order.note);
    return [
      {
        field_name: 'pickup_date',
        display_name: 'Pickup Date',
        type: 'date',
        span: 4,
      },
      ...selected.map((order, index) => ({
        field_name: order.order_name,
        display_name: `Include Order ${order.order_name}'s notes in shipping notes\nNotes: ${order.note}`,
        renderLabel: () => (
          <Box borderTop={'1px solid ' + colors.border} paddingTop="10px" marginTop={index === 0 ? '20px' : undefined}>
            <Typography fontSize="18px">
              Add <span style={{ color: colors.secondary, fontWeight: 'bold' }}>Order {order.order_name}</span>'s note
              to the shipping note?
            </Typography>
            <Typography>
              <b>Note:</b> "{order.note}"
            </Typography>
          </Box>
        ),
        type: 'radiogroup',
        span: 4,
        options: [
          { key: 'Yes', value: 'yes' },
          { key: 'No', value: 'no' },
        ],
      })),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrdersDetails, selectedOrders]);

  const processShipmentInitialValue = useMemo(
    () =>
      processShipmentFields.reduce((value: any, field) => {
        value[field.field_name] = '';
        return value;
      }, {}),
    [processShipmentFields]
  );

  const processShipmentSchema = useMemo(() => {
    const schema = processShipmentFields.reduce(
      (value: any, field) => {
        value[field.field_name] = yup.string().required('Required');
        return value;
      },
      { pickup_date: yup.string().required('Pickup Date is required') }
    );

    return yup.object().shape(schema);
  }, [processShipmentFields]);

  return (
    <Box>
      <Header title="Batches" mb="0" />
      <CustomTable
        ref={batchTableRef}
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        getData={getAllBatches}
        searchKeys="batch name or number"
      />
      <CustomModal header={'Rename Batch'} open={openUpdateModal} setOpen={setOpenUpdateModal} width={500}>
        <CustomForm
          initialValues={selectedBatch}
          onSubmit={handleSubmit}
          fields={[
            {
              field_name: 'batch_name',
              display_name: 'Batch Name',
              span: 4,
            },
          ]}
          loading={buttonLoading}
        />
      </CustomModal>

      <CustomModal
        header={`SN-${selectedBatch?.id}: ${selectedBatch?.batch_name} - Orders Added ( ${selectedOrdersDetails.length} )`}
        open={openViewOrders}
        setOpen={setOpenViewOrders}
        width={'90%'}
        onClose={() => {
          setSelectedOrders([]);
          setSelectedOrdersDetails([]);
          setOrderCount(0);
          setSelectedBatch(undefined);
          batchTableRef.current.refreshData();
        }}
      >
        <Box display="none">
          <Box ref={printableRef}>
            {selectedOrdersDetails
              .filter((order) => !selectedOrders.length || selectedOrders.includes(order.order_name))
              .map((order) => (
                <PickList
                  key={order.order_name}
                  batchNumber={selectedBatch?.id!}
                  order={order}
                  skuLocations={skuLocations}
                />
              ))}
          </Box>
          <Box ref={printableCombineRef}>
            <CombinePickList
              batchNumber={selectedBatch?.id!}
              orders={selectedOrdersDetails.filter(
                (order) => !selectedOrders.length || selectedOrders.includes(order.order_name)
              )}
              skuLocations={skuLocations}
            />
          </Box>
          <Box ref={printableCommercialInvoice}>
            {selectedOrdersDetails
              .filter((order) => !selectedOrders.length || selectedOrders.includes(order.order_name))
              .map((order) => (
                <CommercialInvoice key={order.order_name} order={order} skuLocations={skuLocations} />
              ))}
          </Box>
        </Box>

        <CustomTable
          ref={orderTableRef}
          rowId="order_name"
          columns={
            selectedBatch?.status === 'error'
              ? order_table_columns.filter(
                  (column) => !['tote_bin_number', 'note', 'tracking_number'].includes(column.field)
                )
              : order_table_columns.filter((column) => column.field !== 'dear_errors')
          }
          rows={selectedOrdersDetails}
          rowCount={orderCount}
          loading={loading}
          getData={(query) => viewOrders(query)}
          searchKeys="order no., tags, or notes"
          keepNonExistentRowsSelected
          checkboxSelection
          autoSelectedRows={selectedOrders}
          handleSelectRow={(selected) => setSelectedOrders(selected as any)}
          initialOrderBy="tote_bin_number"
          headerComponent={
            selectedBatch?.status === 'error' ? (
              <Box display="flex" gap="10px">
                <RegularButton
                  label={'Resolve Selected Orders'}
                  disabled={!selectedOrders.length}
                  onClick={() => setOpenResolveOrders(true)}
                />
                <RegularButton
                  color="warning"
                  label={'Partial Fulfillment Selected Orders'}
                  disabled={!selectedOrders.length}
                  onClick={() => setOpenConfirmPartial(true)}
                />
                <RegularButton color="info" label={'View Summarized Errors'} onClick={openErrors} />
              </Box>
            ) : (
              <Box display="flex" gap="10px" alignItems="center">
                {selectedBatch?.status === 'partial' && (
                  <RegularButton color="info" label={'View Summarized Errors'} onClick={openErrors} />
                )}
                <RegularButton
                  label={'Generate pick list'}
                  onClick={() => {
                    setDocumentToPrint('Pick-List');
                    handlePrintPickList();
                  }}
                  loading={skuLocationsLoading}
                />
                <RegularButton
                  color="warning"
                  label={
                    selectedBatch?.picklist_printed ? 'Regenerate combine pick list' : 'Generate combine pick list'
                  }
                  onClick={() => {
                    if (selectedBatch?.picklist_printed) {
                      printCombinedPickList();
                    } else {
                      setOpenPrintCPConfirmation(true);
                    }
                  }}
                  loading={skuLocationsLoading}
                />
                <RegularButton
                  color="secondary"
                  label={'Generate Commercial Invoice'}
                  onClick={() => {
                    let label = ` (${selectedOrders.length ? selectedOrders.length : orderCount} of ${orderCount})`;
                    if (selectedOrders.length === 1) {
                      label = '-' + selectedOrders[0];
                    }
                    setDocumentToPrint(`CI-Combined${label}`);
                    handlePrintCommercialInvoice();
                  }}
                  loading={skuLocationsLoading}
                  disabled={disableCommercialInvoiceOrPrintLabel}
                />
                <RegularButton
                  color="success"
                  label={'Reprint Label'}
                  onClick={() => {
                    const selected = selectedOrdersDetails.filter(
                      (order) => !selectedOrders.length || selectedOrders.includes(order.order_name)
                    );

                    setLoadingPrint(true);
                    printLabel(selected.map((order) => order.order_name))
                      .then((res) => {
                        if (res.data)
                          downloadPdf(
                            res.data,
                            selected[0]?.shipping_carrier.carrier_name,
                            selected.length === 1 ? selected[0].order_name : undefined
                          );
                      })
                      .finally(() => setLoadingPrint(false));
                  }}
                  loading={loadingPrint}
                  disabled={disableCommercialInvoiceOrPrintLabel}
                />
                {hasToteBinIssue && (
                  <RegularButton
                    color="success"
                    label="Fix Tote Bin Number"
                    loading={fixToteBinLoading}
                    onClick={async () => {
                      if (selectedBatch) {
                        try {
                          setFixToteBinLoading(true);
                          await fixToteBinNumber(selectedBatch.id);
                          orderTableRef.current.refreshData();
                        } finally {
                          setFixToteBinLoading(false);
                        }
                      }
                    }}
                  />
                )}

                {selectedOrders.length > 0 && <Typography fontWeight="bold">for selected orders</Typography>}
              </Box>
            )
          }
          filterComponent={
            selectedBatch?.status === 'error' ? undefined : (
              <Box display="flex" gap="10px" alignItems="center">
                <Typography fontWeight="bold">Process Shipment:</Typography>
                {user?.shipping_carriers
                  .filter((carrier) => ![SHIPPING_COMPANY.SINGAPORE_POST].includes(carrier.carrier_name))
                  .map((carrier, index) => (
                    <RegularButton
                      key={index}
                      color={['info', 'secondary', 'warning'][index % 3] as any}
                      variant="outlined"
                      label={carrier.carrier_name}
                      onClick={() => {
                        setSelectedCarrier(carrier.carrier_name);
                        setOpenProcessShipment(true);
                      }}
                      styles={{ width: '150px' }}
                      disabled={!selectedOrders.length}
                      loading={processShipmentLoading}
                    />
                  ))}
              </Box>
            )
          }
        />
      </CustomModal>

      <CustomModal
        header={`Summarized Errors`}
        open={openSummarizedErrors}
        setOpen={setOpenSummarizedErrors}
        width={900}
        onClose={() => setSummarizedErrors([])}
      >
        <Box display="grid" gridTemplateColumns="150px 1fr 150px" width="100%" rowGap="5px">
          <Typography fontWeight="bold">No. of Orders</Typography>
          <Typography fontWeight="bold">Error</Typography>
          <Typography fontWeight="bold" color="error">
            Total Qty
          </Typography>
        </Box>
        <Box display="grid" gridTemplateColumns="150px 1fr 150px" width="100%" rowGap="5px">
          {summarizedErrors.map((error, index) => (
            <Fragment key={index}>
              <Typography>{error.count}</Typography>
              <Typography>{error.error}</Typography>
              <Typography color="error">
                {error.error.startsWith('Insufficient on hand quantity') ? error.total_qty : 'N/A'}
              </Typography>
            </Fragment>
          ))}
        </Box>
      </CustomModal>

      <CustomModal
        header={`Set Pickup Date - ${selectedCarrier}`}
        open={openProcessShipment}
        setOpen={setOpenProcessShipment}
        width={500}
        onClose={() => {
          setSummarizedErrors([]);
          setSelectedCarrier(undefined);
        }}
      >
        {!invalidOrderSelected.length ? (
          <CustomForm
            initialValues={processShipmentInitialValue}
            onSubmit={handleSubmitProcessShipment}
            fields={processShipmentFields}
            loading={processShipmentLoading}
            schema={processShipmentSchema}
            gap="10px"
          />
        ) : (
          <Box>
            <Typography sx={{ textIndent: '20px' }}>
              The following orders cannot be processed as Ninja Van does not operate in the destination country:
            </Typography>
            <Box
              sx={{
                padding: '20px',
                my: '20px',
                border: '2px solid ' + colors.primary,
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
              }}
            >
              {invalidOrderSelected.map((order) => (
                <Typography fontSize="18px" fontWeight="bold">
                  {order.order_name} - {order.shipping_country}
                </Typography>
              ))}
            </Box>
            <Typography>
              Please unselect the orders above if you want to process the shipment with Ninja Van.
            </Typography>
          </Box>
        )}
      </CustomModal>

      <OrderValidation
        title="Resolve Selected Orders"
        open={openResolveOrders}
        setOpen={setOpenResolveOrders}
        orders={selectedOrders}
        batch_number={selectedBatch?.id}
        onClose={() => {
          setSelectedOrders([]);
          orderTableRef.current.refreshData();
        }}
      />

      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure want to remove this order?"
        onConfirm={() =>
          removeFromBatch(selectedOrder?.id!).then(() => {
            orderTableRef.current.refreshData();
          })
        }
      />

      <ConfirmationDialog
        open={openPrintCPConfirmation}
        setOpen={setOpenPrintCPConfirmation}
        title="Confirmation"
        message="Are you sure you want to generate the Combined Pick List? Confirming this will mark the batch as printed, and this action cannot be undone."
        onConfirm={printCombinedPickList}
        loading={loadingPrintCP}
      />

      <ConfirmationDialog
        open={openConfirmPartial}
        setOpen={setOpenConfirmPartial}
        message={`Are you sure you want to record ${selectedOrders.length} order${
          selectedOrders.length > 1 ? 's' : ''
        } as partial fulfillment?`}
        onConfirm={() => handlePartialFulfillment()}
        loading={loadingPartial}
      />
    </Box>
  );
};

export default Batches;
