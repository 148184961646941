import { Box, Typography } from '@mui/material';
import { ConfirmationDialog, CustomTable, CustomTextField, PrimaryButton } from 'components';
import { addMissingOrder, getUnfulfilledInShopify } from 'api/order';
import { capitalizeWord, formatDate, getTotalDaysFromDate } from 'utils';

import { ApiQuery } from 'model/interface';
import { OrderModel } from 'model/Entities';
import { useState } from 'react';

type Props = {
  setTotalCount: (value: number) => void;
};

const UnfulfilledOrdersTable: React.FC<Props> = ({ setTotalCount }) => {
  const [unfulfilledOrders, setUnfulfilledOrders] = useState<any[]>([]);
  const [addLoading, setAddLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderModel>();
  const [orderNumber, setOrderNumber] = useState('');

  const getUnfulfilled = async (query: ApiQuery) => {
    const { data } = await getUnfulfilledInShopify(query);
    setUnfulfilledOrders(data.orders);
    setTotalCount(data.orders.length);
  };

  return (
    <Box>
      <CustomTable
        rows={unfulfilledOrders}
        searchKeys="Order No."
        columns={[
          {
            field: 'name',
            sortable: false,
            headerName: 'Order No.',
            flex: 1,
          },
          {
            field: 'created_at',
            headerName: 'Order Date',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => <Typography>{formatDate(value)}</Typography>,
          },
          {
            field: 'age',
            headerName: 'Days Unfulfilled',
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => <Typography>{getTotalDaysFromDate(row.created_at)}</Typography>,
          },
          {
            field: 'line_items',
            headerName: 'SKU',
            flex: 1.5,
            sortable: false,
            renderCell: ({ row }) => (
              <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="10px">
                {row.line_items.map((item: any, index: number) => (
                  <Typography key={index}>{item.sku}</Typography>
                ))}
              </Box>
            ),
          },
          {
            field: 'shipping_country',
            headerName: 'Shipping Country',
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => <Typography>{row.shipping_address?.country}</Typography>,
          },
          {
            field: 'tags',
            headerName: 'Tags',
            flex: 1,
            sortable: false,
          },
          {
            field: 'note',
            headerName: 'Notes',
            flex: 1,
            sortable: false,
          },
        ]}
        rowCount={0}
        getData={getUnfulfilled}
        headerComponent={
          <Box display="flex" flexDirection="column" gap="10px">
            <Box display="flex" alignItems="center" gap="10px" mt="10px">
              <CustomTextField
                label="Order No."
                fieldName="order"
                sx={{ width: '120px' }}
                value={orderNumber}
                handleChange={(event) => setOrderNumber(event.target.value)}
              />
              <PrimaryButton
                label="Add Missing Order"
                loading={addLoading}
                disabled={!orderNumber}
                onClick={() => {
                  setAddLoading(true);
                  addMissingOrder(orderNumber)
                    .then((res) => {
                      setOrderDetails(res.data);
                      setOpenConfirmation(true);
                    })
                    .finally(() => setAddLoading(false));
                }}
              />
            </Box>
            <Typography>
              Note: WUDI is only showing a maximum of 100 orders, but there could be more orders that are still
              unfulfilled in Shopify.
            </Typography>
          </Box>
        }
      />

      <ConfirmationDialog
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title="Confirmation"
        message={
          orderDetails?.wudi_status === 'existed'
            ? `Order ${orderDetails?.order_name} already exists in WUDI and is currently in the ${capitalizeWord(
                orderDetails.current_station
              )} Station`
            : orderDetails?.wudi_status === 'deleted'
            ? `Order ${orderDetails?.order_name} has been removed. Please check the order in the Removed tab.`
            : orderDetails?.wudi_status === 'not_found'
            ? `Order ${orderNumber} was not found or has been archived in Shopify.`
            : `Order ${orderDetails?.order_name} has been successfully added to WUDI.`
        }
        cancelButtonLabel="Okay"
        onClose={() => setOrderNumber('')}
      />
    </Box>
  );
};

export default UnfulfilledOrdersTable;
